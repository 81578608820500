import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyC5MmLZxkm1dXL5j2PoZUoUMiB1zviIbnw",
    authDomain: "trangbbapp.firebaseapp.com",
    databaseURL: "https://trangbbapp-default-rtdb.firebaseio.com",
    projectId: "trangbbapp",
    storageBucket: "trangbbapp.appspot.com",
    messagingSenderId: "201696964151",
    appId: "1:201696964151:web:dd2a7cc23e1d7bee59d9b2"
};

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, firestore, storage };


