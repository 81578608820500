import I1 from "../img/i1.png";
import F1 from "../img/f1.png";
import C3 from "../img/c3.png";
import Fi1 from "../img/fi1.png";

export const heroData = [
  {
    id: 1,
    name: "Trung Thu",
    decp: "vật liệu tre",
    price: "95,000",
    imageSrc: I1,
  },
  {
    id: 2,
    name: "Giỏ Cói Đan",
    decp: "vật liệu Cói",
    price: "45,000",
    imageSrc: F1,
  },
  {
    id: 3,
    name: "Đĩa Song",
    decp: "sứ, song",
    price: "200,000",
    imageSrc: C3,
  },
  {
    id: 4,
    name: "Lồng bàn",
    decp: "tre, màn chống ruồi",
    price: "150,000",
    imageSrc: Fi1,
  },
];

export const categories = [
  {
    id: 1,
    name: "Tre",
    urlParamName: "tre",
  },
  {
    id: 2,
    name: "Cói",
    urlParamName: "coi",
  },
  {
    id: 3,
    name: "Song",
    urlParamName: "song",
  },
  {
    id: 4,
    name: "Ruột mây",
    urlParamName: "may",
  },
  {
    id: 5,
    name: "Trung Thu",
    urlParamName: "trungthu",
  },
  {
    id: 6,
    name: "Giỏ Tết",
    urlParamName: "tet",
  },

  {
    id: 7,
    name: "Khay khảm",
    urlParamName: "kham",
  },
];
