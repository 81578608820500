import React from 'react';
import EnviromentImg from "./img/environment.png";
import AvatarImg from "./img/avatar1.png";
const AboutUs = () => {
  return (
    
    <div className="bg-green-50 py-8">
      <div className="container mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl text-teal-600 font-semibold">Về Chúng Tôi</h1>
          <p className="text-gray-600">Chúng tôi cam kết làm thế giới trở nên xanh hơn và khỏe mạnh hơn.</p>
        </div>
        <div className="flex flex-col md:flex-row items-center mb-8">
          <div className="md:w-1/2 mb-6 md:mb-0">
            <img src={EnviromentImg} alt="Môi trường" className="rounded-lg shadow-md" />
          </div>
          <div className="md:w-1/2">
            <div className="text-center md:text-left">
              <h2 className="text-2xl text-teal-600 font-semibold mb-2">Sứ Mệnh Của Chúng Tôi</h2>
              <p className="text-gray-700">Chào mừng quý khách hàng đến với chúng tôi - một công ty sản xuất đồ mỹ nghệ tre tại Việt Nam, với hơn 15 năm kinh nghiệm trong lĩnh vực này. Chúng tôi tự hào là một phần của hành trình đan xen giữa nghệ thuật và thiên nhiên, mang đến những sản phẩm tinh tế và thân thiện môi trường.

Sứ mệnh của chúng tôi không chỉ đơn thuần là sản xuất ra những tác phẩm nghệ thuật tre độc đáo, mà còn là việc thể hiện tôn trọng và bảo vệ môi trường. Tại chúng tôi, mỗi sản phẩm đều được tạo ra bằng cách tận dụng tài nguyên tre tái chế, giúp giảm thiểu tác động tiêu cực lên môi trường. Chúng tôi không chỉ định hướng tới sự sáng tạo và đẳng cấp trong từng sản phẩm, mà còn mong muốn lan tỏa thông điệp về tầm quan trọng của bảo vệ hành tinh cho cộng đồng.

Với hơn 32 công nhân tài năng, tận tâm và giàu kinh nghiệm, chúng tôi đã xây dựng nên một môi trường làm việc đoàn kết, động viên sáng tạo và đảm bảo chất lượng tối đa cho từng sản phẩm chúng tôi mang đến. Diện tích nhà xưởng rộng lớn, 1500 mét vuông, cung cấp không gian lý tưởng cho việc chế tác, sáng tạo và sản xuất những tác phẩm tre độc đáo.

Qua nhiều năm hoạt động, chúng tôi đã xây dựng được danh tiếng vững chắc trong việc tạo ra những sản phẩm mang giá trị nghệ thuật cao cấp, từ đồ trang trí nội thất đến những món quà độc đáo. Sự cam kết với chất lượng và độ uy tín trong mỗi sản phẩm đã giúp chúng tôi trở thành đối tác tin cậy của nhiều cá nhân, doanh nghiệp và tổ chức.

Hãy đồng hành cùng chúng tôi trên hành trình tạo ra sự hoàn hảo và tôn trọng môi trường qua những sản phẩm đồ mỹ nghệ tre độc đáo. Chúng tôi rất mong được phục vụ quý khách hàng và mang đến những trải nghiệm tuyệt vời nhất thông qua sự kết hợp giữa nghệ thuật và thiên nhiên tại mỗi tác phẩm của chúng tôi.</p>
              <p className="text-gray-700">Email: trangbamboovn@gmail.com</p>
              <p className="text-gray-700">SDT: 0977877318</p>
              <p className="text-gray-700">Facebook: trangphong1993</p>
            </div>
          </div>
        </div>
        <div className="text-center mb-4">
          <h2 className="text-2xl text-teal-600 font-semibold mb-2">Gặp Chúng Tôi</h2>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="md:w-1/3 px-4 mb-8 md:mb-0">
            <img src={AvatarImg} alt="Thành viên đội ngũ 1" className="rounded-full w-32 h-32 mx-auto mb-4" />
            <h3 className="text-lg font-semibold text-gray-800">Nguyễn Thu Trang</h3>
            <p className="text-gray-600"> CEO</p>
          </div>
          <div className="md:w-1/3 px-4 mb-8 md:mb-0">
            <img src="member2.jpg" alt="Thành viên đội ngũ 2" className="rounded-full w-32 h-32 mx-auto mb-4" />
            <h3 className="text-lg font-semibold text-gray-800">Đỗ Xuân Hiếu</h3>
            <p className="text-gray-600">CTO</p>
          </div>
          <div className="md:w-1/3 px-4">
            <img src="member3.jpg" alt="Thành viên đội ngũ 3" className="rounded-full w-32 h-32 mx-auto mb-4" />
            <h3 className="text-lg font-semibold text-gray-800">Nguyễn Thị Nga</h3>
            <p className="text-gray-600">CFO</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
