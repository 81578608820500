import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { CreateContainer, Header, MainContainer, Footer } from "./components";
import { useStateValue } from "./context/StateProvider";
import { getAllFoodItems } from "./utils/firebaseFunctions";
import { actionType } from "./context/reducer";
import Aboutus from "./Aboutus";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5MmLZxkm1dXL5j2PoZUoUMiB1zviIbnw",
  authDomain: "trangbbapp.firebaseapp.com",
  databaseURL: "https://trangbbapp-default-rtdb.firebaseio.com",
  projectId: "trangbbapp",
  storageBucket: "trangbbapp.appspot.com",
  messagingSenderId: "201696964151",
  appId: "1:201696964151:web:dd2a7cc23e1d7bee59d9b2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const App = () => {
  const [{ foodItems }, dispatch] = useStateValue();

  const fetchData = async () => {
    await getAllFoodItems().then((data) => {
      dispatch({
        type: actionType.SET_FOOD_ITEMS,
        foodItems: data,
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AnimatePresence mode="wait">
      <div className="w-screen h-auto flex flex-col bg-primary">
        <Header />

        <main className="mt-14 md:mt-20 px-4 md:px-16 py-4 w-full">
          <Routes>
            <Route path="/*" element={<MainContainer />} />
            <Route path="/createItem8993" element={<CreateContainer />} />
            <Route path="/aboutus" element={<Aboutus />} />




          </Routes>
        </main>
        <Footer />

      </div>
    </AnimatePresence>

  );
};

export default App;