
import React from 'react';

const Footer = () => {
    return (
        
        <div className="grid h-50px place-items-center ">
            {/* style={{flexDirection:'column',alignItems:'center'}} */}
            <div>
                <p className='font-bold'>CONTACT:</p>

            </div>
            <div className="flex items-center justify-between">
            
                <p>||</p>
                <p>Address: Phu Nghia, Chuong My, Ha Noi</p>
                <p>||</p>
                <p>Phone: 0977877318</p>
                <p>||</p>
                <p>email: trangbamboovn@gmail.com</p>
                <p>||</p>
                
            </div>
            <div className='text-emerald-800'>
                <p>Copy Right Khanh Nguyen Co Ltd.</p>

            </div>
           

        </div>


    )
}

export default Footer;

